import Vue from 'vue'
import less from 'less'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import { sync } from 'vuex-router-sync'
import './styles/global.less'

import App from './App.vue'
import router from './router'
import store from './store'
// import Fmui from 'fmui'
import Fmui from '@/components/fmui'
import {init} from './syslib/appInit'

import SignPage from '@/components/base/SignPage'
import SignBlock from '@/components/base/SignBlock'
import DataUpdate from '@/components/base/DataUpdate'
import VNumTo from '@/components/base/NumTo'
// import { configsRequest } from '@/api'

import OrderTable from '@/views/order/detail/orderTable'

import 'view-design/dist/styles/iview.css'

Vue.directive('num-to', VNumTo)

Vue.component(OrderTable.name, OrderTable)
Vue.component(SignPage.name, SignPage)
Vue.component(SignBlock.name, SignBlock)
Vue.component(DataUpdate.name, DataUpdate)

Vue.config.productionTip = false
Vue.use(less)
Vue.use(Fmui)
// Vue.use(ElementUI)

Vue.prototype.$authFunProxy = new Proxy({}, {
  get (obj, prop) {
    return (store.getters.currentRole && store.getters.currentRole.id === 1) || (store.getters.availableFunKeys.includes(prop))
  }
})

Vue.prototype.$authFunsProxy = new Proxy({}, {
  get (obj, prop) {
    return (store.getters.currentRole && store.getters.currentRole.id === 1) || (store.getters.availableFunKeys.includes(store.state.route.name + '-' + prop))
  }
})

sync(store, router)

init()

// Vue.prototype.$sysConfigs = {}

// Vue.prototype.$loadTableConfigs = async function (type, key = undefined) {
//   key = key ? (store.state.route.name + '-' + key) : key
//   let res = await configsRequest.get({type, key})
//   res.forEach(item => {
//     item.value = JSON.parse(item.value, function (k, value) {
//       if (k === 'dataType') {
//         switch (value) {
//           case 'Number':
//             return Number
//           case 'Date':
//             return Date
//           case 'String':
//             return String
//         }
//       }
//       return value
//     })
//     Vue.prototype.$sysConfigs[item.type + ':' + item.configKey] = {
//       id: item.id,
//       type: item.type,
//       configKey: item.configKey,
//       value: item.value
//     }
//   })
//   return res
// }

// Vue.prototype.$getTableConfig = function (key, baseColumns) {
//   let configs = (Vue.prototype.$sysConfigs['table:' + store.state.route.name + '-' + key] || {value: []}).value
//   return baseColumns.map(v => {
//     let config = v.field ? configs.find(n => n.field === v.field) : null
//     return config ? Object.assign({}, v, config) : v
//   })
// }

// Vue.prototype.$onTableColumnUpdate = function (columns, key) {
//   let configs = columns.map(column => {
//     return {
//       field: column.field,
//       hidden: column.hidden,
//       fixed: column.fixed,
//       dataType: column.dataType,
//       sort: column.sort,
//       width: column.width,
//       order: column.order
//     }
//   })
//   let json = JSON.stringify(configs, function (k, value) {
//     switch (value) {
//       case Number:
//         return 'Number'
//       case Date:
//         return 'Date'
//       case String:
//         return 'String'
//       default:
//         return value
//     }
//   })

//   let configKey = store.state.route.name + '-' + key

//   configsRequest.add({
//     id: (Vue.prototype.$sysConfigs[configKey] ? Vue.prototype.$sysConfigs[configKey] : {id: undefined}).id,
//     type: 'table',
//     configKey: configKey,
//     value: json
//   })
// }

// Vue.prototype.$tableColumnUpdate = function (table, key) {
//   table.$off('on-column-config-update').$on('on-column-config-update', (columns) => Vue.prototype.$onTableColumnUpdate(columns, key))
// }

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
