const state = {
  theme: 'blue'
}

const getters = {
  theme: state => state.theme,
  themeList: () => {
    return [
      {label: '蓝色主题', key: 'blue', status: 1, color: '#005AFF'}
    ]
  }
}

const actions = {
  switchTheme ({commit}, theme) {
    commit('switchTheme', theme)
  }
}

const mutations = {
  switchTheme (state, theme) {
    state.theme = theme
  }
}

export default {
  state, getters, actions, mutations
}