<template>
  <base-sign-block
    :title-text="titleText"
    :note-text="noteText"
    :column-list="columnList"
    :data-list="dataList"
    :search-parms="searchParms"
    :form-parms="formParms"
    :his-data="chooseData"
    :toolbox="showToolBox"
    :export-file-name="exportFileName"
    :openDialog="openDialog"
    @formHandleClose="openDialog = false"
    @formSubmit="formSubmit"
    @clickSearch="loadData"
    @countDataChange="countDataChange"
    :dataFileDirPath="dataFileDirPath"
    :data-file-title="dataFileTitle"
    :open-dialog-data-file="openDialogDataFile"
    @dataFileHandleClose="openDialogDataFile = false"
    :dataFilefuns="useDataFilefuns"
    :dataFileUploadCheck="dataFileUploadCheck"
    @dataFileUpdate="dataFileUpdate"
    :count-fun="countFun"
    :show-search="true"
    :loading="loading"
    @tableAction="tableAction"
    :on-form-change="onFormChange"
    :table-actions="tableActions"
    :table-actions-width="tableActionsWidth"
    :table-actions-fixed="tableActionsFixed"
    :title-menus="titleMenus"
    @clickTitleMenu="clickTitleMenu"
    :simple-filter="simpleFilter"
    :table-size="tableSize"
    ref="page"
    :need-pagination="true">
    <template v-slot:title>
      <slot name="title">
      </slot>
      <slot>
      </slot>
    </template>
  </base-sign-block>
</template>

<script>
import BaseSignBlock from '@/components/base/BaseSignBlock'

import {
  dateOperating
} from '@/fmlib'

export default {
  name: 'SignBlock',
  components: {
    BaseSignBlock
  },
  props: {
    pageDelData: {
      type: Function
    },
    initAddFormData: {
      type: Function
    },
    dataFileUploadCheck: {
      type: Function
    },
    pageFormSubmit: {
      type: Function
    },
    dataFilefuns: {
      type: Object
    },
    dataFileParm: {
      type: Object
    },
    onFormChange: {
      type: Function
    },
    titleText: {
      type: String
    },
    initSearchData: {
      type: Object
    },
    dealSearchParm: {
      type: Function
    },
    delSuccessInfo: {
      type: Function
    },
    request: {
      type: Object
    },
    formParmsAdd: {
      type: Array
    },
    formParmsUpdate: {
      type: Array
    },
    titleMenus: {
      type: Array,
      default: () => {
        return [{
          key: 'add',
          label: '新增'
        }]
      }
    },
    tableActions: {
      type: Array,
      default: () => {
        return [{
          key: 'edit',
          label: '修改'
        },
        {
          key: 'del',
          label: '删除'
        }]
      }
    },
    searchParms: {
      type: Array
    },
    dealData: {
      type: Function
    },
    dealFormData: {
      type: Function
    },
    columnList: {
      type: Array
    },
    tableActionsWidth: { type: Number, default: null },
    tableActionsFixed: { type: Boolean, default: false },
    simpleFilter: { type: Boolean, default: true },
    tableSize: { type: String, default: 'norm' },
    exportFileName: { default: '导出数据' }
  },
  created () {
    this.loadData(this.initSearchData)
  },
  computed: {
    showToolBox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    useDataFilefuns () {
      return {
        get: this.dataFilefuns && this.dataFilefuns.get,
        upload: this.dataFilefuns && this.dataFilefuns.upload,
        down: this.dataFilefuns && this.dataFilefuns.down,
        del: this.dataFilefuns && this.dataFilefuns.del,
        mkdir: this.dataFilefuns && this.dataFilefuns.mkdir
      }
    },
    dataFileTitle () {
      return (this.tableActions.find(v => v.key === 'data_file') || {label: ''}).label
    },
    dataFileDirPath () {
      if (this.chooseData && this.dataFileParm && this.dataFileParm.pDirPath) {
        return this.dataFileParm.pDirPath + this.chooseData.id + '/'
      }
      return null
    }
  },
  methods: {
    dataFileUpdate (parm) {
      this.$emit('dataFileUpdate', parm, this)
    },
    getTableData () {
      return this.$refs.page.getTableData()
    },
    countFun (data) {
      return data.length
    },
    countDataChange (data) {
      this.noteText = '总数:' + data
    },
    tableAction (data) {
      this.chooseData = data.data
      let action = this.tableActions.find(v => v.key === data.action)
      if (action.dealFun) {
        action.dealFun(this, this.chooseData)
      } else if (data.action === 'edit') {
        this.formParms = this.formParmsUpdate
        this.openDialog = true
      } else if (data.action === 'data_file') {
        this.openDialogDataFile = true
      } else if (data.action === 'del') {
        this.delData()
      } else {
        this.$emit('tableAction', data, this)
      }
    },
    async delData () {
      const result = await this.$dialog.confirm({title: '系统提示', content: '确定删除' + this.titleText.replace('管理', '') + '吗?'})
      if (result) {
        if (this.pageDelData) {
          await this.pageDelData({vm: this, data: this.chooseData})
          return
        }
        this.request.del(this.chooseData.id).then(() => {
          this.$notice.info({
            title: '系统提示',
            desc: this.delSuccessInfo ? this.delSuccessInfo(this.titleText) : (this.titleText.replace('管理', '') + '已删除')
          })
          this.$emit('dataUpdate', this.chooseData)
          this.loadData()
        })
      }
    },
    async formSubmit (data, resolve) {
      if (this.dealFormData) {
        try {
          data = this.dealFormData(data, this)
        } catch (e) {
          resolve()
          return
        }
      }
      for (let key in data) {
        if (data[key] instanceof Date) {
          data[key] = dateOperating.computeDay({days: 0, date: data[key], format: 'yy-mm-dd hh:mm:ss'})
        }
        if (data[key] instanceof Array) {
          data[key] = data[key].join(',')
        }
      }
      if (this.pageFormSubmit) {
        try {
          await this.pageFormSubmit({vm: this, data})
          this.openDialog = false
          resolve()
        } catch (error) {
          console.log(error)
          resolve()
        }
        return
      }
      if (this.chooseData && this.chooseData.id) {
        this.request.update(this.chooseData.id, data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: this.titleText.replace('管理', '') + '修改完成'
          })
          this.loadData()
          this.$emit('dataUpdate', data)
          this.openDialog = false
          resolve()
        }).catch(() => {
          resolve()
        })
      } else {
        this.request.add(data).then(() => {
          this.$notice.success({
            title: '系统提示',
            desc: this.titleText.replace('管理', '') + '新增完成'
          })
          this.loadData()
          this.$emit('dataUpdate', data)
          this.openDialog = false
          resolve()
        }).catch(() => {
          resolve()
        })
      }
    },
    clickTitleMenu (key, parm) {
      this.chooseData = null
      if (key === 'add') {
        if (this.initAddFormData) {
          this.chooseData = this.initAddFormData()
        }
        this.formParms = this.formParmsAdd
        this.openDialog = true
      } else {
        if (parm.dealFun) {
          parm.dealFun(this)
        } else {
          this.$emit('clickTitleMenu', key)
        }
      }
    },
    async loadData (searchParm) {
      try {
        let searchParmData = this.dealSearchParm ? this.dealSearchParm(searchParm) : searchParm
        this.searchData = searchParmData || this.searchData
        this.loading = true
        let data = await this.request.get(this.searchData)
        if (this.dealData) {
          this.dataList = await this.dealData(data)
        } else {
          this.dataList = data
        }
        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    }
  },
  data () {
    return {
      formParms: [],
      chooseList: [],
      searchData: {},
      dataList: [],
      noteText: '',
      openDialogDataFile: false,
      loading: true,
      chooseData: null,
      openDialog: false,
      openDialogFile: false
    }
  }
}
</script>
