import {
  axiosInit
} from '@/fmlib'
import * as url from './url'

import sysEnv from '@/env/env'

import store from '@/store'

import Vue from 'vue'

export const baseUrl = sysEnv.apiAdr

let showMessageFun = (msg) => {
  Vue.prototype.$notice.error({
    title: '系统提示',
    desc: msg
  })
}

let showMessageFunNoNotice = (msg) => {
  console.log('api调用异常:', msg)
}

export const getHeaderParm = () => {
  return {
    token: store.getters.token,
    roleIds: store.getters.currentRole ? store.getters.currentRole.id : null
  }
}

let axiosInitOne = new axiosInit.axiosInit({baseUrl, showMessageFun, getHeaderParm})
let axiosInitOneNoNotice = new axiosInit.axiosInit({baseUrl, showMessageFun: showMessageFunNoNotice, getHeaderParm})

export const request = axiosInitOne.request
export const requestNoNotice = axiosInitOneNoNotice.request

let httpRequestMethods = axiosInitOne.httpRequestMethods

// file
export const fileRequest = {
  mkdir: function (parm) {
    return request(url.file.mkdir, httpRequestMethods.POST, parm)
  },
  upload: function (parm) {
    return request(url.file.upload, httpRequestMethods.POST, parm, {
      contentType: 'multipart/form-data'
    })
  },
  get: function (parm) {
    return requestNoNotice(url.file.download, httpRequestMethods.GET, parm)
  },
  download: function (parm) {
    return request(url.file.download, httpRequestMethods.GET, parm, {
      responseType: 'blob'
    })
  },
  del: function (parm) {
    return request(url.file.del, httpRequestMethods.DELETE, parm)
  }
}

export const generateBasisRequest = function (urlParm) {
  return {
    get: function (parm) {
      return request(urlParm.basUrl, httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(urlParm.basUrl, httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(urlParm.parmUrl(id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(urlParm.parmUrl(id), httpRequestMethods.DELETE)
    }
  }
}

export const generateBasisDataRequest = function (name) {
  return {
    get: function (parm) {
      return request(url.data.get(name), httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(url.data.add(name), httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(url.data.update(name, id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(url.data.del(name, id), httpRequestMethods.DELETE)
    }
  }
}

export const generateCommonRequest = function (name) {
  return {
    get: function (parm) {
      return request(url.common.get(name), httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(url.common.add(name), httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(url.common.update(name, id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(url.common.del(name, id), httpRequestMethods.DELETE)
    }
  }
}

export const refQueryRequestHander = async function (key, parm) {
  return await request(url.refQuery.get(key), httpRequestMethods.GET, parm)
}

// systemApis
export function systemApisGet () {
  return request(url.systemApis.url, httpRequestMethods.GET)
}

// menuApis
export function menuApisUpate (id, parm) {
  return request(url.menuApis.parmUrl(id), httpRequestMethods.POST, parm)
}

export const userRequest = generateBasisRequest(url.user)

export const roleRequest = generateBasisRequest(url.role)

export const funRequest = Object.assign(generateBasisRequest(url.fun), {
  updateApi (id, parm) {
    return request(url.fun.updateApi(id), httpRequestMethods.POST, parm)
  }
})

export const menuRequest = generateBasisRequest(url.menu)

// auth
export function login (parm) {
  return request(url.auth.login, httpRequestMethods.POST, parm)
}

export function getLoginPubKey (parm) {
  return request(url.auth.getPubKey, httpRequestMethods.POST, parm)
}

export function updatePwd (parm) {
  return request(url.auth.updatePwd, httpRequestMethods.PUT, parm)
}

export function loginUpdatePwd (parm, token) {
  let get = () => {
    return {
      token
    }
  }
  return new axiosInit.axiosInit({baseUrl, showMessageFun, getHeaderParm: get}).request(url.auth.updatePwd, httpRequestMethods.PUT, parm)
}

export function userLogOut () {
  return request(url.auth.logOut, httpRequestMethods.DELETE)
}

export function getUserInfo () {
  return request(url.auth.getInfo, httpRequestMethods.GET)
}

// resetPassword
export function resetPassword (id) {
  return request(url.resetPassword.parmUrl(id), httpRequestMethods.PUT)
}

export const ucenterRequest = {
  updateAuthorities (id, parm) {
    return request(url.ucenter.authorities(id), httpRequestMethods.POST, parm)
  },
  getAuthorities (parm) {
    return request(url.ucenter.users, httpRequestMethods.GET, parm)
  }
}

// dbOperationLog
export const dbOperationLogRequest = {
  get (parm) {
    return refQueryRequestHander('get_db_operation_log', parm)
  }
}

export const dataAuditLogReuqest = {
  get: function (parm) {
    return request(url.dataAuditLog.base, httpRequestMethods.GET, parm)
  }
}

export const operationLogRequest = {
  getLog: function (parm) {
    return request(url.operationLog.log, httpRequestMethods.GET, parm)
  }
}

// confQuery
export const confQueryRequest = generateCommonRequest('conf_query')

// medicine 药品
export const medicineRequest = generateCommonRequest('medicine')

// medicineType 药品种类
export const medicineTypeRequest = generateCommonRequest('medicine_type')

// mainbody
export const mainbodyRequest = Object.assign(generateCommonRequest('mainbody'), {
  getAll: function (parm) {
    return request('/platform/mainbody/all', httpRequestMethods.GET, parm)
  }
})

// supplier 供应商
export const supplierRequest = generateCommonRequest('supplier')

// clinic 诊所
export const clinicRequest = generateCommonRequest('clinic')

// patient 患者
export const patientRequest = generateCommonRequest('patient')

// illness_case 病例
export const illnessCaseRequest = generateCommonRequest('illness_case')

// prescription_tmp 处方模版
export const prescriptionTmpRequest = Object.assign({}, generateCommonRequest('prescription_tmp'), {
  updateDetail: function (id, parm) {
    return request('/platform/prescription_tmp/detail/' + id, httpRequestMethods.PUT, parm)
  },
  addDetail: function (parm) {
    return request('/platform/prescription_tmp/detail', httpRequestMethods.POST, parm)
  },
  delDetail: function (id) {
    return request('/platform/prescription_tmp/detail/' + id, httpRequestMethods.DELETE)
  },
  mainBodyMedicine: function (id, parm) {
    return request('/platform/prescription_tmp/mainbody_medicine/' + id, httpRequestMethods.GET, parm)
  },
  getToCheck: function (parm) {
    return request('/platform/prescription_tmp/check', httpRequestMethods.GET, parm)
  }
})

// 获取数据、新增数据、修改的参数
// [{
//   ...数据库中 illness_case 表结构中字段
//   // 患者信息
//   patientData: {
//     ...数据库中 patient 表结构中字段
//   },
//   // 诊断信息
//   diagnosisData: {
//     ...数据库中 diagnosis 表结构中字段
//   },
//   // 处方组信息
//   prescriptionGroup: {
//     ...数据库中 prescription_croup 表结构中字段
//     // 处方信息
//     prescriptionData: [{
//       ...数据库中 prescription_croup 表结构中字段
//       medicineData: [{
//         ...数据库中 prescription_medicine 表结构中字段,
//         medicineName: 药品名称
//       }...]
//     }...]
//   }
// }...]

// inventory 库存 目前只有查询接口
export const inventoryRequest = generateCommonRequest('inventory')

// inventory_detail 出入库
export const inventoryDetailRequest = generateCommonRequest('inventory_detail')

// mainbody_medicine 库存配置
export const mainbodyMedicineRequest = Object.assign(generateCommonRequest('mainbody_medicine'), {
  getBase: function (parm) {
    return request('/platform/mainbody_medicine/base', httpRequestMethods.GET, parm)
  },
  getBaseAll: function (parm) {
    return request('/platform/mainbody_medicine/base/all', httpRequestMethods.GET, parm)
  }
})

// mainbody_medicine_group 库存配置组
export const mainbodyMedicineGroupRequest = Object.assign(generateCommonRequest('mainbody_medicine_group'), {
  getBuy: function (parm) {
    return request('/platform/mainbody_medicine_group/buy', httpRequestMethods.GET, parm)
  }
})

// order 采购单
export const orderRequest = Object.assign(generateCommonRequest('order'), {
  submit: function (id) {
    return request('/platform/order/submit/' + id, httpRequestMethods.POST)
  },
  backSubmit: function (id) {
    return request('/platform/order/submit/back/' + id, httpRequestMethods.POST)
  },
  pass: function (id) {
    return request('/platform/order/check/' + id, httpRequestMethods.POST, {status: 'pass'})
  },
  down: function (id) {
    return request('/platform/order/check/' + id, httpRequestMethods.POST, {status: 'down'})
  },
  back: function (id, parm) {
    return request('/platform/order/back/' + id, httpRequestMethods.POST, parm)
  },
  pay: function (id, parm) {
    return request('/platform/order/pay/' + id, httpRequestMethods.POST, parm)
  },
  backPay: function (id, parm) {
    return request('/platform/order/back/pay/' + id, httpRequestMethods.POST, parm)
  },
  backPayDown: function (id) {
    return request('/platform/order/back/pay/down/' + id, httpRequestMethods.POST)
  },
  illnessCase: function (id) {
    return request('/platform/order/illness_case/' + id, httpRequestMethods.POST)
  },
  prescription: function (parm) {
    return request('/platform/order/prescription', httpRequestMethods.POST, parm)
  }
})

// order_detail 采购单明细
export const orderDetailRequest = generateCommonRequest('order_detail')

// delivery_order 配送单
export const deliveryOrderRequest = Object.assign(generateCommonRequest('delivery_order'), {
  getDetail: function (id) {
    return request('/platform/delivery_order/detail/' + id, httpRequestMethods.GET)
  },
  receipt: function (id) {
    return request('/platform/delivery_order/receipt/' + id, httpRequestMethods.POST)
  }
})

export const diseaseRequest = Object.assign({
  getSymptom (parm) {
    return request('/platform/disease/symptom', httpRequestMethods.GET, parm)
  }
}, generateCommonRequest('disease'))