<template>
  <info-block
    :data="order"
    :fields="order && order.id ? (order.type === 'prescription' ? fieldsPrescription :  fields) : []"
    title="详细信息"></info-block>
</template>

<script>
import InfoBlock from '@/components/base/InfoBlock'

import {
  orderRequest
} from '@/api'

export default {
  name: 'BaseInfo',
  components: {
    InfoBlock
  },
  props: {
    orderId: {
      type: Number
    }
  },
  computed: {
    fieldsPrescription: {
      get () {
        let data = [{
          title: '患者',
          field: 'buyMainbodyLabel'
        },
        {
          title: '订单号',
          field: 'id'
        },
        {
          title: '创建时间',
          field: 'createTime',
          format: (rowData) => {
            return rowData && rowData.createTime ? rowData.createTime.slice(0, 16) : '-'
          }
        }]
        if (this.order && this.order.type && this.order.type.includes('back')) {
          data.push({
            title: '退货原因',
            field: 'reason'
          })
          data.push({
            title: '退货说明',
            field: 'description'
          })
        }
        return data
      }
    },
    fields: {
      get () {
        let data = [{
          title: '收货人',
          field: 'receiptName'
        },
        {
          title: '采购人',
          field: 'makeUserName'
        },
        {
          title: '采购机构',
          field: 'buyMainbodyLabel'
        },
        {
          title: '供应商',
          field: 'saleMainbodyLabel'
        },
        {
          title: '联系电话',
          field: 'receiptPhone'
        },
        {
          title: '订单号',
          field: 'id'
        },
        {
          title: '创建时间',
          field: 'createTime',
          format: (rowData) => {
            return rowData && rowData.createTime ? rowData.createTime.slice(0, 16) : '-'
          }
        },
        {
          title: '收货地址',
          field: 'receiptAddress'
        }]
        if (this.order && this.order.type && this.order.type.includes('back')) {
          data.push({
            title: '退货原因',
            field: 'reason'
          })
          data.push({
            title: '退货说明',
            field: 'description'
          })
        }
        return data
      }
    }
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      order: {}
    }
  },
  methods: {
    async loadData () {
      let datas = await orderRequest.get({id: this.orderId})
      this.order = datas[0]
      this.$emit('getOrder', this.order)
    }
  }
}
</script>
