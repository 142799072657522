export default [
  // {
  //   path: '/',
  //   name: 'home',
  //   redirect: '/worker/count'
  // },
  {
    path: '/',
    name: 'app',
    component: () => import('@/views/app'),
    children: [
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/app/nullPage')
      },
      {
        path: '/conf',
        name: 'conf',
        component: () => import('@/views/conf'),
        children: [
          {
            path: 'conf_query',
            name: 'conf.conf_query',
            component: () => import('@/views/conf/conf_query'),
          }
        ]
      },
      {
        path: '/authority',
        name: 'authority',
        component: () => import('@/views/authority'),
        children: [
          {
            path: 'user',
            name: 'authority.user',
            component: () => import('@/views/authority/user'),
          },
          {
            path: 'role',
            name: 'authority.role',
            component: () => import('@/views/authority/role'),
          },
          {
            path: 'menu',
            name: 'authority.menu',
            component: () => import('@/views/authority/menu'),
          },
          {
            path: 'fun',
            name: 'authority.fun',
            component: () => import('@/views/authority/fun')
          },
          {
            path: 'operation_log',
            name: 'authority.operation_log',
            component: () => import('@/views/authority/operationLog')
          }
        ]
      },
      {
        path: '/clinic',
        name: 'clinic',
        component: () => import('@/views/clinic/router'),
        children: [
          {
            path: 'index',
            name: 'clinic.index',
            component: () => import('@/views/clinic/index')
          }
        ]
      },
      {
        path: '/order',
        name: 'order',
        component: () => import('@/views/order/router'),
        children: [
          {
            path: 'buy',
            name: 'order.buy',
            component: () => import('@/views/order/buy')
          },
          {
            path: 'buy_back',
            name: 'order.buy_back',
            component: () => import('@/views/order/buyBack')
          },
          {
            path: 'sale_back',
            name: 'order.sale_back',
            component: () => import('@/views/order/saleBack')
          },
          {
            path: 'sale_bill',
            name: 'order.sale_bill',
            component: () => import('@/views/order/saleBill')
          },
          {
            path: 'sale_check',
            name: 'order.sale_check',
            component: () => import('@/views/order/saleCheck')
          },
          {
            path: 'sale_ship',
            name: 'order.sale_ship',
            component: () => import('@/views/order/saleShip')
          },
          {
            path: 'sale_prescription',
            name: 'order.sale_prescription',
            component: () => import('@/views/order/salePrescription')
          },
          {
            path: 'build',
            name: 'order.build',
            component: () => import('@/views/order/build')
          }
        ]
      },
      {
        path: '/medicine',
        name: 'medicine',
        component: () => import('@/views/medicine/index')
      },
      {
        path: '/medicine_type',
        name: 'medicine_type',
        component: () => import('@/views/medicine/type')
      },
      {
        path: '/medicine/sale',
        name: 'medicine.sale',
        component: () => import('@/views/medicine/sale')
      },
      {
        path: '/supplier',
        name: 'supplier',
        component: () => import('@/views/supplier/router'),
        children: [
          {
            path: 'index',
            name: 'supplier.index',
            component: () => import('@/views/supplier/index')
          },
          {
            path: 'procurement',
            name: 'supplier.procurement',
            component: () => import('@/views/supplier/procurement')
          },
          {
            path: 'pharmacy',
            name: 'supplier.pharmacy',
            component: () => import('@/views/supplier/pharmacy')
          },
          {
            path: 'price',
            name: 'supplier.price',
            component: () => import('@/views/supplier/price')
          }
        ]
      },
      {
        path: '/patients',
        name: 'patients',
        component: () => import('@/views/patients/router'),
        children: [
          {
            path: 'index',
            name: 'patients.index',
            component: () => import('@/views/patients/index')
          },
          {
            path: 'details',
            name: 'patients.details',
            component: () => import('@/views/patients/details'),
            children: [
              {
                path: 'prescription',
                name: 'patients.details.prescription',
                component: () => import('@/views/patients/details/prescription')
              },
              {
                path: 'info',
                name: 'patients.details.info',
                component: () => import('@/views/patients/details/info')
              }
            ]
          }
        ]
      },
      {
        path: '/pharmacy',
        name: 'pharmacy',
        component: () => import('@/views/pharmacy/index')
      },
      {
        path: '/prescription',
        name: 'prescription',
        component: () => import('@/views/prescription/index')
      },
      {
        path: '/prescription_tmp',
        name: 'prescription_tmp',
        component: () => import('@/views/prescription/tmp.vue')
      },
      {
        path: '/prescription_tmp_check',
        name: 'prescription_tmp_check',
        component: () => import('@/views/prescription/tmpCheck.vue')
      },
      {
        path: '/disease/index',
        name: 'disease.index',
        component: () => import('@/views/disease/index.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => /iphone|ios|android|ipad/i.test(navigator.userAgent) ? import('@/views/login/mobile') : import('@/views/login/index')
  }
]
