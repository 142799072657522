<template>
  <fm-modal :mask-closable="false" :value="openDialog" @cancel="handleClose" width="600px">
    <div class="files" v-if="dirPath && openDialog">
      <files :root="dirPath" @fileUpdate="fileUpdate" :upload-check="uploadCheck" :label="fileTitle" :funs="funs"></files>
    </div>
  </fm-modal>
</template>

<script>
import Files from './file/index.vue'

export default {
  components: { Files },
  props: {
    fileTitle: {
      type: String
    },
    dirPath: {
      type: String
    },
    uploadCheck: {
      type: Function
    },
    openDialog: {
      type: Boolean, default: false
    },
    funs: {
      type: Object,
      default: () => {
        return {}
      }
    },

  },
  methods: {
    handleClose () {
      this.$emit('handleClose')
    },
    fileUpdate (parm) {
      this.$emit('fileUpdate', parm)
    }
  }
}
</script>

<style lang="less" scoped>
</style>