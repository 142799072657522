<template>
  <div>
    <fm-table-new
      :simple-filter="simpleFilter"
      :columns="showType === 'prescription' ? columnListPrescription :  columnList"
      :auto-height="autoHeight"
      v-loadingx="loading"
      border="row"
      ref="table"
      :data-list="dataList"
      :stripe="false"
      size="norm"
      emptyPlaceholder="-">
      <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
      <div slot="empty" style="margin: 30px 0;">
        <img src="/static/images/null.png" style="width: 40px;"/>
        <div>暂无数据</div>
      </div>
    </fm-table-new>
    <fm-modal :mask-closable="false" :title="showType === 'prescription' ? '订单明细' : '采购单明细'" v-model="modal" width="1000px">
      <all-info :orderType="showType" :order-id="chooseData.id" v-if="chooseData && chooseData.id && modal"></all-info>
    </fm-modal>
    <fm-modal :mask-closable="false" title="采购单退货" v-model="modalBack" width="1000px">
      <back @cancel="modalBack = false" :order-id="chooseData.id" v-if="chooseData && chooseData.id && modalBack"></back>
    </fm-modal>
    <fm-modal :mask-closable="false" title="退款" v-model="modalBackPay" width="1000px">
      <back-pay @cancel="modalBackPay = false" @bcakPayOver="bcakPayOver" :order-id="chooseData.id" v-if="chooseData && chooseData.id && modalBackPay"></back-pay>
    </fm-modal>
    <fm-modal :mask-closable="false" title="采购单发货" v-model="modalShip" width="1000px">
      <ship @cancel="modalShip = false" :order-id="chooseData.id" v-if="chooseData && chooseData.id && modalShip"></ship>
    </fm-modal>
    <fm-modal :mask-closable="false" :title="payType === 'give' ? '付款' : '收款'" v-model="modalPay" width="400px">
      <pay @cancel="modalPay = false" @payOver="payOver" :type="payType" :order-id="chooseData.id" v-if="chooseData && chooseData.id && modalPay"></pay>
    </fm-modal>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'

import AllInfo from './allInfo'
import Back from './back'
import BackPay from './backPay'
import Ship from './ship'
import Pay from './pay'

import {
  orderRequest
} from '@/api'

export default {
  name: 'orderTable',
  components: {
    TableActions,
    AllInfo,
    Back,
    BackPay,
    Ship,
    Pay
  },
  props: {
    simpleFilter: { type: Boolean, default: true },
    dataList: {
      type: Array
    },
    showType: {
      type: String
    },
    autoHeight: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      loading: false,
      chooseData: null,
      payType: 'give',
      modal: false,
      modalBack: false,
      modalShip: false,
      modalPay: false,
      modalBackPay: false
    }
  },
  computed: {
    userMainbodyId () {
      return this.$store.getters.userMainbodyId
    },
    tableActions () {
      let vm = this
      let data = [{
        key: 'detail',
        label: '明细'
      },
      {
        key: 'back',
        label: '退货',
        show (data) {
          return data.type === 'normal' && data.status === 'pass' && data.buyMainbodyId === vm.userMainbodyId
        }
      },
      {
        key: 'update',
        label: '调整',
        show (data) {
          return data.type === 'normal' && data.status === 'edit' && data.buyMainbodyId === vm.userMainbodyId
        }
      },
      {
        key: 'submit',
        label: '提交',
        show (data) {
          return data.type === 'normal' && data.status === 'edit' && data.buyMainbodyId === vm.userMainbodyId
        }
      },
      {
        key: 'backSubmit',
        label: '撤回',
        show (data) {
          return data.type === 'normal' && data.bill && data.bill.status === 0 && data.status === 'wait' && data.buyMainbodyId === vm.userMainbodyId
        }
      },
      {
        key: 'pass',
        label: '通过',
        show (data) {
          return vm.canCheck(data)
        }
      },
      {
        key: 'down',
        label: '不通过',
        show (data) {
          return vm.canCheck(data)
        }
      },
      {
        key: 'ship',
        label: '发货',
        show (data) {
          return data.status === 'pass' && data.totalVolumeWaitShip > 0 && data.type != 'back_order' && data.type != 'prescription' && data.saleMainbodyId === vm.userMainbodyId
        }
      },
      {
        key: 'pay',
        label: '收款',
        show (data) {
          return !['down'].includes(data.status) && data.bill && data.bill.status === 0 && data.saleMainbodyId === vm.userMainbodyId
        }
      },
      {
        key: 'pay_give',
        label: '付款',
        show (data) {
          return !['down'].includes(data.status) && data.bill && data.bill.status === 0 && data.buyMainbodyId === vm.userMainbodyId
        }
      },
      {
        key: 'backPay',
        label: '退款',
        show (data) {
          return data.type == 'prescription' && data.bill && data.bill.status === 1
        }
      },
      {
        key: 'backPayDown',
        label: '退款',
        show (data) {
          return data.status == 'down' && data.bill && data.bill.status === 1 && data.saleMainbodyId === vm.userMainbodyId
        }
      }]
      return data
    },
    columnListPrescription: {
      get () {
        return [{
          title: '患者姓名',
          field: 'buyMainbodyLabel',
          sort: true
        },
        {
          title: '订单类型',
          field: 'oType',
          sort: true,
          render: (h, row) => {
            if (row.type === 'direct') {
              return h('div', '直接售药')
            } else {
              return h('div', '处方售药')
            }
          }
        },
        {
          title: '订单状态',
          field: 'ddzt',
          sort: true,
          render: (h, rowData) => {
            let text = '待支付'
            if (rowData.bill && rowData.bill.status === 1) {
              text = '已支付'
              if (rowData.bill.refund === rowData.bill.paid) {
                text = '已退款'
              } else if (rowData.bill.refund > 0) {
                text = '部分退款'
              }
            }
            return h('div', text)
          }
        },
        {
          title: '订单时间',
          field: 'createTime',
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.createTime ? rowData.createTime.slice(0, 10) : '-')
          },
          sort: true
        },
        {
          title: '总金额',
          field: 'totalAmount',
          render: (h, rowData) => {
            return h('div', rowData && rowData.bill ? rowData.bill.totalAmount || '-' : '-')
          }
        },
        {
          title: '减免金额',
          field: 'otherFee',
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.otherFee ? rowData.bill.otherFee || '-' : '-')
          }
        },
        {
          title: '应付金额',
          field: 'payable',
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.bill ? rowData.bill.payable || '-' : '-')
          }
        },
        {
          title: '实付金额',
          field: 'paid',
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.bill ? rowData.bill.paid || '-' : '-')
          }
        },
        {
          title: '退款金额',
          field: 'refund',
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.bill ? rowData.bill.refund || '-' : '-')
          }
        },
        {
          title: '开方人',
          field: 'makeUserName',
          sort: true
        },
        {
          title: '收款方式',
          field: 'payType',
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.bill ? rowData.bill.payType || '-' : '-')
          }
        },
        {
          title: '操作',
          slot: 'actions',
          // width: 100,
          fixed: 'right',
          search: false,
          export: false,
          configurable: false
        }]
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '订单号',
          field: 'id',
          fixed: 'left',
          sort: true
        },
        {
          title: '日期',
          field: 'createTime',
          dataType: Date,
          render: (h, rowData) => {
            return h('div', rowData && rowData.createTime ? rowData.createTime.slice(0, 10) : '-')
          },
          sort: true
        },
        {
          title: '供应商',
          field: 'saleMainbodyLabel',
          sort: true
        },
        {
          title: '总金额',
          field: 'totalAmount',
          render: (h, rowData) => {
            return h('div', rowData && rowData.bill ? rowData.bill.totalAmount || '-' : '-')
          }
        },
        {
          title: '优惠券金额',
          field: 'discount',
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.bill ? rowData.bill.discount || '-' : '-')
          }
        },
        {
          title: '减免金额',
          field: 'otherFee',
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.otherFee ? rowData.bill.otherFee || '-' : '-')
          }
        },
        {
          title: '应付金额',
          field: 'payable',
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.bill ? rowData.bill.payable || '-' : '-')
          }
        },
        {
          title: '实付金额',
          field: 'paid',
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.bill ? rowData.bill.paid || '-' : '-')
          }
        },
        {
          title: '下单方式',
          field: 'makeType',
          sort: true
        },
        {
          title: '订单状态',
          field: 'statusText',
          sort: true
        },
        {
          title: '操作',
          slot: 'actions',
          // width: 100,
          fixed: 'right',
          search: false,
          export: false,
          configurable: false
        }]
        return data
      }
    }
  },
  methods: {
    payOver () {
      this.modalPay = false
      this.$emit('dataUpdate')
    },
    bcakPayOver () {
      this.modalBackPay = false
      this.$emit('dataUpdate')
    },
    canCheck (data) {
      let pass = data.status === 'wait'
      if (pass) {
        if (data.type === 'normal') {
          pass = data.saleMainbodyId === this.userMainbodyId
        } else if (data.type.includes('back')) {
          pass = data.buyMainbodyId === this.userMainbodyId
        } else {
          pass = false
        }
      }
      return pass
    },
    async tableAction (parm, vm) {
      this.pageVm = vm
      this.chooseData = parm.data
      let action = parm.action
      if (action === 'update') {
        this.$router.push({name: 'order.build', query: {id: this.chooseData.id}})
      } else if (action === 'submit') {
        await orderRequest.submit(this.chooseData.id)
        this.$emit('dataUpdate')
      } else if (action === 'backSubmit') {
        await orderRequest.backSubmit(this.chooseData.id)
        this.$emit('dataUpdate')
      } else if (action === 'detail') {
        this.modal = true
      } else if (action === 'back') {
        this.modalBack = true
      } else if (action === 'pass') {
        await orderRequest.pass(this.chooseData.id)
        this.$emit('dataUpdate')
      } else if (action === 'down') {
        await orderRequest.down(this.chooseData.id)
        this.$emit('dataUpdate')
      } else if (action === 'ship') {
        this.modalShip = true
      } else if (action === 'pay') {
        this.payType = 'get'
        this.modalPay = true
      } else if (action === 'pay_give') {
        this.payType = 'give'
        this.modalPay = true
      } else if (action === 'backPay') {
        this.modalBackPay = true
      } else if (action === 'backPayDown') {
        const result = await this.$dialog.confirm({title: '系统提示', content: '已付金额(' + this.chooseData.bill.paid + '),退款金额(' + this.chooseData.bill.paid + ')吗?'})
        if (result) {
          await orderRequest.backPayDown(this.chooseData.id)
          this.$emit('dataUpdate')
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
</style>
