export const envs = {
  huatuo: {
    env: 'huatuo',
    webPort: '',
    hostName: 'huatuo.e-forward.cn',
    apiAdr: 'https://api.huatuo.e-forward.cn',
    remark: 'huatuo',
    ipc: '皖ICP备2021009694号'
  },
  dev: {
    env: 'dev',
    webPort: '',
    hostName: 'dev.huatuo.e-forward.cn',
    apiAdr: 'https://api.dev.huatuo.e-forward.cn',
    remark: 'dev',
    ipc: '皖ICP备2021009694号'
  },
  demo: {
    env: 'demo',
    webPort: '',
    hostName: 'demo.huatuo.e-forward.cn',
    apiAdr: 'https://api.demo.huatuo.e-forward.cn',
    remark: 'demo',
    ipc: '皖ICP备2021009694号'
  }
}
  
const initEnv = () => {
  // 根据前端接口来判断环境
  let env = null
  let hostName = window.location.hostname
  let useEnvs = Object.keys(envs).filter(key => envs[key].hostName === hostName)
  console.log(useEnvs, 'useEnvs')
  let webPort = window.location.port
  if (useEnvs.length > 0) {
    env = envs[useEnvs[0]]
    if (useEnvs.length > 1) {
      let useEnvKey = useEnvs.find(key => envs[key].webPort === webPort)
      console.log(useEnvKey)
      if (useEnvKey) {
        env = envs[useEnvKey]
      }
    }
  }
  console.log(env ? ('环境:' + env.remark) : '获取环境失败, 使用' + envs['dev'].remark)
  return env || envs['dev']
}

export default initEnv()
