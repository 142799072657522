<template>
  <div class="delivery-order" v-if="dataList.length > 0">
    <fm-title title-text="快递信息"></fm-title>
    <fm-table-new
      simple-filter
      :columns="columns"
      v-loadingx="loading"
      border="row"
      ref="table"
      size="small"
      :data-list="dataList"
      :stripe="false"
      emptyPlaceholder="-">
      <div slot="empty" style="margin: 30px 0;">
        <img src="/static/images/null.png" style="width: 40px;"/>
        <div>暂无数据</div>
      </div>
      <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row, index }" :data="tableActions" :index="index" :row="row"></table-actions>
    </fm-table-new>
    <fm-modal :mask-closable="false" title="快递明细" v-model="modalDetail" width="1000px" v-if="modalDetail">
      <info-block
        :data="chooseData"
        :fields="fields"></info-block>
      <fm-table-new
        simple-filter
        :columns="columnsDetail"
        v-loadingx="loading"
        border="row"
        ref="table"
        size="small"
        :data-list="detailDataList"
        :stripe="false"
        emptyPlaceholder="-">
        <div slot="empty" style="margin: 30px 0;">
          <img src="/static/images/null.png" style="width: 40px;"/>
          <div>暂无数据</div>
        </div>
      </fm-table-new>
    </fm-modal>
  </div>
</template>

<script>
import {
  deliveryOrderRequest
} from '@/api'

import TableActions from '@/components/base/TableActions'
import InfoBlock from '@/components/base/InfoBlock'

export default {
  name: 'DeliveryOrder',
  components: { TableActions, InfoBlock },
  props: {
    orderId: { type: Number, default: null }
  },
  created () {
    this.loadData()
  },
  computed: {
    tableActions () {
      let vm = this
      return [{
        key: 'detail',
        label: '明细'
      },
      {
        key: 'receipt',
        label: '收货',
        show (data) {
          return data.status === 'doing' && data.receiptMainbodyId === vm.$store.getters.userMainbodyId
        }
      }]
    },
    fields () {
      return [{
        field: 'deliveryCompany',
        title: '快递公司'
      },
      {
        field: 'code',
        title: '快递单号'
      },
      {
        field: 'createTime',
        title: '发货时间'
      },
      {
        field: 'makeUserName',
        title: '操作用户'
      }]
    },
    columns () {
      let datas = [{
        field: 'code',
        title: '快递单号'
      },
      {
        field: 'deliveryCompany',
        title: '快递公司'
      },
      {
        field: 'status', 
        title: '发货状态'
      },
      {
        field: 'makeUserName',
        title: '操作用户'
      },
      {
        field: 'createTime',
        title: '操作时间'
      },
      {
        field: 'total',
        title: '发货总数'
      },
      {
        title: '操作',
        field: 'actions',
        width: 100,
        slot: 'actions',
        fixed: 'right',
        search: false,
        export: false,
        configurable: false
      }]
      return datas
    },
    columnsDetail () {
      let datas = [{
        field: 'medicineName',
        title: '药品名称'
      },
      {
        field: 'medicineManufacturer',
        title: '生产厂商'
      },
      {
        field: 'medicinePacking',
        title: '药品规格'
      },
      {
        field: 'volume',
        title: '发货数量'
      },
      {
        field: 'productionBatch',
        title: '生产批号'
      },
      {
        field: 'productionDate',
        title: '生产日期'
      },
      {
        field: 'expiredDate',
        title: '有效期至'
      }]
      return datas
    }
  },
  methods: {
    async tableAction (parm) {
      this.chooseData = parm.data
      if (parm.action === 'detail') {
        this.loadDetail()
        this.modalDetail = true
      } else if (parm.action === 'receipt') {
        this.loading = true
        await deliveryOrderRequest.receipt(this.chooseData.id)
        this.$emit('orderDetailUpdate')
        this.loadData()
        this.loading = false
      }
    },
    async loadData () {
      if (this.orderId) {
        this.loading = true
        let parm = {
          orderId: this.orderId
        }
        this.dataList = await deliveryOrderRequest.get(parm)
        this.loading = false
      }
    },
    async loadDetail () {
      this.detailDataList = []
      this.detailDataList = await deliveryOrderRequest.getDetail(this.chooseData.id)
    }
  },
  data () {
    return {
      chooseData: null,
      dataList: [],
      loading: false,
      modalDetail: false,
      detailDataList: []
    }
  }
}
</script>

<style lang="less" scope>
.order-detail {
  width: 100%;
  height: 100%;
}
.left-page {
  display: block;
  flex: 2;
}
.right-page {
  flex: 1;
}
</style>