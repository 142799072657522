<template>
  <div class="bcak-block" v-loadingx="loading">
    <base-info :order-id="orderId"></base-info>
    <buy-info :order-id="orderId"></buy-info>
    <detail :order-id="orderId" ref="bcakOrderDetail" @backChange="backChange" action="back"></detail>
    <div class="back-form" style="margin-top:2rem;">
      <fm-form :inline="false">
        <fm-form-item label="退货原因">
          <fm-input-new type="textarea" v-model="order.reason"/>
        </fm-form-item>
        <fm-form-item label="退货说明">
          <fm-input-new type="textarea" v-model="order.description"/>
        </fm-form-item>
      </fm-form>
    </div>
    <div class="btm-btn">
      <fm-btn style="margin-right:3rem" @click="save">退货</fm-btn>
      <fm-btn @click="cancel">取消</fm-btn>
    </div>
  </div>
</template>

<script>
import {
  orderRequest
} from '@/api'

import BaseInfo from './baseInfo'
import BuyInfo from './buyInfo'
import Detail from './detail'

export default {
  name: 'BackOrder',
  components: {
    BaseInfo,
    BuyInfo,
    Detail
  },
  props: {
    orderId: {
      type: Number
    }
  },
  data () {
    return {
      detailList: [],
      order: {
        description: '',
        reason: ''
      },
      loading: true
    }
  },
  created () {
    this.loadOrder()
  },
  methods: {
    async loadOrder () {
      this.loading = true
      let datas = await orderRequest.get({id: this.orderId})
      this.order = Object.assign({}, datas[0], {
        description: '',
        reason: ''
      })
      this.loading = false
    },
    backChange (data) {
      this.detailList = data.filter(v => v.applyVolumeShipReceiptBack || v.applyVolumeBack).map((v) => {
        return {
          id: v.id,
          applyVolumeBack: v.applyVolumeBack,
          applyVolumeShipReceiptBack: v.applyVolumeShipReceiptBack
        }
      })
    },
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      if (this.detailList.length === 0) {
        this.$notice.info({
          title: '系统提示',
          desc: '无退货量，无法退货!'
        })
        return
      }
      this.loading = true
      let parm = Object.assign({}, this.order, {
        orderIds: String(this.orderId)
      })
      parm.details = this.detailList
      this.detailList = []
      await orderRequest.back(this.orderId, parm)
      this.$notice.info({
        title: '系统提示',
        desc: '退货完成!'
      })
      this.loading = false
      this.$refs['bcakOrderDetail'].loadData()
      this.$emit('bcakOver')
    }
  }
}
</script>


<style lang="less" scope>
.btm-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}
</style>