
const generateBasisUrl = function (url) {
  return Object.freeze({
    basUrl: url,
    parmUrl: (id) => url + `/${id}`,
  })
}

export const auth = Object.freeze({
  login: '/ucenter/oauth/token',
  getPubKey: '/ucenter/oauth/pre/token',
  getInfo: '/ucenter/user/current',
  logOut: '/ucenter/oauth/token',
  updatePwd: '/ucenter/user/myPassword'
})

export const menuApis = Object.freeze({
  parmUrl: (id) => `/ucenter/system/menus/${id}/apis`
})

export const userRole = Object.freeze({
  parmUrl: (userId) => `/ucenter/user/${userId}/roles`
})

export const resetPassword = Object.freeze({
  parmUrl: (userId) => `/ucenter/user/${userId}/password/default`
})

export const systemApis = Object.freeze({
  url: '/ucenter/system/apis'
})

export const refQuery = Object.freeze({
  get: (key) => `/platform/data/ref/query/${key}`
})

export const user = generateBasisUrl('/ucenter/user')

export const role = generateBasisUrl('/ucenter/system/roles')

export const fun = Object.assign({}, generateBasisUrl('/ucenter/system/funs'), {
  updateApi: (id) => `/ucenter/system/funs/${id}/apis`
})

export const menu = generateBasisUrl('/ucenter/system/menus')

export const data = Object.freeze({
  get: (name) => `/platform/data/${name}`,
  add: (name) => `/platform/data/${name}`,
  update: (name, id) => `/platform/data/${name}/${id}`,
  del: (name, id) => `/platform/data/${name}/${id}`,
})

export const common = Object.freeze({
  get: (name) => `/platform/${name}`,
  add: (name) => `/platform/${name}`,
  update: (name, id) => `/platform/${name}/${id}`,
  del: (name, id) => `/platform/${name}/${id}`,
})

export const file = Object.freeze({
  mkdir: '/fms/file/mkdir',
  upload: '/fms/upload',
  download: '/fms/download',
  del: '/fms/file'
})


export const ucenter = Object.freeze({
  authorities: (id) => `/ucenter/user/${id}/authorities`,
  users: '/ucenter/user'
})

export const operationLog = Object.freeze({
  get: '/platform/operation_log'
})

export const dataAuditLog = Object.freeze({
  base: '/platform/data/audit/log'
})

export const operationLong = Object.freeze({
  log: '/platform/operation/log'
})
