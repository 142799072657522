<style lang="less" scoped>
  .pay-group {
    display: flex;
    span {
      font-size: 15px;
      border-radius: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0 15px;
      cursor: pointer;
      border: 1px solid #CCC;
      border-bottom: none;
      line-height: 45px;
      background-color: rgba(86, 125, 253, .1);
      color: rgba(86, 125, 253, 1);
      transition: all .3s;
      &.active {
        cursor: default;
        border-color: rgba(86, 125, 253, 1);
        background-color: rgba(86, 125, 253, 1);
        color: #FFF;
      }
      & + span {
        margin-left: 10px;
      }
    }
  }
  .pay-block {
    border: 1px solid rgba(86, 125, 253, 1);
    background-color: rgba(86, 125, 253, .05);
    padding: 35px 15px;
    border-radius: 5px;
    border-top-left-radius: 0;
  }
  .pay-type {
    display: flex;
    align-items: center;
    .item {
      cursor: pointer;
      background-color: #FFF;
      border: 1px solid #EEE;
      transition: all .3s;
      padding: 5px 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      span {
        font-size: 15px;
        margin-left: 10px;
      }
      i {
        font-size: 25px;
      }
      &::before, &::after {
        opacity: 0;
        transition: all .3s;
        position: absolute;
        content: '';
        bottom: 0;
        right: 0;
      }
      &::before {
        border-style: solid;
        border-width: 12px 16px;
        border-color: transparent rgba(86, 125, 253, 1) rgba(86, 125, 253, 1) transparent;
      }
      &::after {
        height: 13px;
        bottom: 4px;
        right: 6px;
        width: 6px;
        border: 1px solid #fff;
        transform: rotate(45deg);
        border-top: 0;
        border-left: 0;
      }
      &.active {
        border-color: rgba(86, 125, 253, 1);
        color: rgba(86, 125, 253, 1);
        &::before, &::after {
          opacity: 1;
        }
      }
    }
  }
  .action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;
    button {
      padding: 10px 20px;
      border-radius: 5px;
      background: rgb(86, 125, 253);
      color: #FFF;
      font-size: 14px;
      border: none;
      cursor: pointer;
      transition: all .3s;
      &:active {
        background: rgb(73, 114, 249);
      }
      &:disabled {
        background: #DDD;
        color: #707070;
        cursor: not-allowed;
      }
    }
  }
  .money {
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 5px;
    }
    .unit {
      font-size: 15px;
    }
    .amount {
      font-size: 30px;
      font-weight: bolder;
      color: red;
    }
  }
  .input {
    position: relative;
    .title {
      display: block;
      color: #000;
      font-size: 18px;
      margin-bottom: 10px;
    }
    input {
      text-align: center;
      font-size: 23px;
      font-weight: bold;
      color: #657180;
      line-height: 30px;
      background-color: #FFF;
      border-radius: 5px;
      border: 1px solid #DDD;
      display: block;
      width: 100%;
      padding: 4px 0;
    }
    .unit {
      position: absolute;
      right: 10px;
      top: 45px;
      color: #888;
    }
  }
  .cell {
    padding: 10px 0;
    display: flex;
    line-height: 50px;
    justify-content: space-between;
    .name {
      font-size: 18px;
      color: 000;
    }
  }
</style>

<template>
  <div v-loadingx="loading">
    <div class="cell">
      <span class="name">应收金额</span>
      <span class="value">
        <div class="money">
          <span class="amount">{{billData.paid}}</span>
          <span class="unit">元</span>
        </div>
      </span>
    </div>
    <fm-form v-if="canEdit">
      <fm-form-item label="收货人姓名" v-if="canEdit">
        <fm-input-new v-model="order.receiptName"/>
      </fm-form-item>
      <fm-form-item label="收货人电话" v-if="canEdit">
        <fm-input-new v-model="order.receiptPhone"/>
      </fm-form-item>
      <fm-form-item label="收货人地址" v-if="canEdit">
        <fm-input-new v-model="order.receiptAddress"/>
      </fm-form-item>
      <!-- <fm-form-item label="应收金额">
        <fm-input-new v-model="billData.payable" :disabled="true"/>
      </fm-form-item>
      <fm-form-item label="支付方式">
        <fm-select v-model="billData.payType" :disabled="!canPay" :clearable="canPay" filterable placeholder="请选择收款方式">
          <fm-option v-for="item in (order.type !== 'prescription' ? ['支付宝', '微信', '银行卡', '现金'] : ['支付宝', '微信', '医保卡','银行卡', '现金'])" :key="item" :value="item" :label="item"></fm-option>
        </fm-select>
      </fm-form-item>
      <fm-form-item label="支付金额">
        <fm-input-new v-model="billData.paid" :disabled="true"/>
      </fm-form-item> -->
    </fm-form>
    <!-- <div class="btm-btn">
      <fm-btn style="margin-right:3rem" @click="save" :disabled="!canPay">{{type === 'get' ? '收款' : '付款'}}</fm-btn>
      <fm-btn @click="cancel">取消</fm-btn>
    </div> -->
    <div class="payment" style="margin-top: 10px;">
      <div class="pay-group">
        <span class="active">普通收款</span>
      </div>
      <div class="pay-block">
        <div class="pay-type">
          <div v-for="item in payType" @click="billData.payType = item.name" class="item" :class="{active: billData.payType === item.name}" :key="item.key">
            <i :class="item.icon" :style="{color: item.color}"></i>
            <span>{{item.name}}</span>
          </div>
        </div>
        <div class="input" style="margin-top: 50px;">
          <div class="title">支付金额</div>
          <input v-model="billData.paid" readonly type="text" />
          <span class="unit">元</span>
        </div>
      </div>
    </div>
    <div class="action">
      <span></span>
      <button @click="save" :disabled="!canPay" class="btn">立即{{type === 'get' ? '收款' : '付款'}}</button>
    </div>
  </div>
</template>

<script>
import {
  orderRequest
} from '@/api'

export default {
  name: 'OrderPay',
  props: {
    orderId: {
      type: Number
    },
    type: {
      type: String,
      default: 'get'
    }
  },
  data () {
    return {
      billData: {
        payable: null,
        payType: null,
        paid: null
      },
      loading: false,
      order: {
        id: this.orderId,
        receiptName: '',
        receiptPhone: '',
        receiptAddress: ''
      }
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    orderType () {
      return this.order && this.order.type ? this.order.type : null
    },
    payType () {
      return [
        { key: 'wxpay', color: 'rgb(89, 192, 93)', icon: 'iconfont icon-wxpay', name: '微信支付' },
        { key: 'alipay', color: 'rgb(94, 181, 239)', icon: 'iconfont icon-alipay', name: '支付宝' },
        { key: 'bank', color: 'rgb(232, 165, 67)', icon: 'iconfont icon-bankpay', name: '银行卡' },
        { key: 'cashpay', color: 'rgb(231, 112, 104)', icon: 'iconfont icon-cashpay', name: '现金' }
      ].concat(this.orderType === 'prescription' ? [{ key: 'yibaopay', color: 'rgb(97, 199, 236)', icon: 'iconfont icon-yibaopay', name: '医保' }] : [])
    },
    canEdit () {
      return this.order && this.order.status === 'edit'
    },
    canPay () {
      return this.billData && this.billData.status === 0
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      if (this.billData.payType) {
        this.loading = true
        if (this.canEdit) {
          await orderRequest.update(this.orderId, this.order)
          await orderRequest.submit(this.orderId)
        }
        await orderRequest.pay(this.orderId, this.billData)
        this.loading = false
        this.$emit('payOver')
      } else {
        this.$notice.warning('请选择付款方式')
      }
    },
    async loadData () {
      this.loading = true
      let orders = await orderRequest.get({id: this.orderId})
      this.order = orders.length > 0 ? orders[0] : null
      this.billData = orders.length > 0 ? orders[0].bill : null
      this.billData.paid = this.billData.payable
      this.loading = false
    }
  }
}
</script>