<style lang="less">
  .info-block {
    .fm-form-content.fm-form-inline .fm-form-item {
      margin-bottom: 0px;
    }
  }
</style>

<template>
  <div class="info-block">
    <fm-title :title-text="title" v-if="title"></fm-title>
    <fm-form :inline="3">
      <fm-form-item v-for="item in fields" :key="item.field" :label="item.title + ':'">
        <div :style="{color: valueColor ? valueColor: ''}">
          {{item.format ? item.format(data) : (data[item.field] === null || data[item.field] === '' ? '-' : data[item.field])}}
        </div>
      </fm-form-item>
    </fm-form>
  </div>
</template>

<script>
export default {
  name: 'InfoBlock',
  props: {
    fields: { type: Array },
    title: { type: String },
    data: { type: Object },
    valueColor: { type: String, default: null }
  }
}
</script>

<style lang="less" scoped>
.info-block {
  width: 100%;
  .card-content {
    display: flex;
    flex-wrap: wrap;
  }
  .info-item {
    display: flex;
    line-height: 2rem;
    margin-right: 3rem;
    .label {
      margin-right: 1rem;
    }
  }
}
</style>
