<template>
  <div class="order-detail">
    <fm-table-new
      simple-filter
      :columns="columns"
      v-loadingx="loading"
      border="row"
      ref="table"
      size="small"
      :data-list="dataList"
      :stripe="false"
      emptyPlaceholder="-">
      <div slot="empty" style="margin: 30px 0;">
        <img src="/static/images/null.png" style="width: 40px;"/>
        <div>暂无数据</div>
      </div>
    </fm-table-new>
  </div>
</template>

<script>
import {
  orderDetailRequest
} from '@/api'

import Volume from './volume'

export default {
  name: 'OrderDetail',
  props: {
    orderId: { type: Number, default: null },
    action: { type: String, default: null },
    orderType: { type: String, default: null }
  },
  created () {
    this.loadData()
  },
  computed: {
    tableActions () {
      return [{
        key: 'del',
        label: '删除'
      }]
    },
    columnsPrescription () {
      return [{
        field: 'medicineName',
        title: '药品名称'
      },
      {
        field: 'medicineManufacturer',
        title: '生产厂商'
      },
      {
        field: 'medicinePacking',
        title: '药品规格'
      },
      {
        field: 'medicineType',
        title: '药品类型'
      },
      {
        field: 'price',
        title: '零售价'
      },
      {
        field: 'volume',
        title: '药品数量'
      },
      {
        field: 'volumeShipReceiptBack',
        title: '退货数量'
      },
      {
        field: 'total',
        title: '药品总金额'
      },
      {
        field: 'tkje',
        title: '退款金额',
        render: (h, rowData) => {
          let text = rowData.volumeShipReceiptBack && rowData.price ? Number((rowData.volumeShipReceiptBack * rowData.price).toFixed(2)) : '-'
          return h('div', text)
        }
      }]
    },
    columnsBase () {
      return [{
        field: 'medicineName',
        title: '药品名称'
      },
      {
        field: 'medicineManufacturer',
        title: '生产厂商'
      },
      {
        field: 'medicinePacking',
        title: '药品规格'
      },
      {
        field: 'price',
        title: '单价（元）'
      },
      {
        field: 'volume',
        title: '采购数量'
      },
      {
        field: 'volumeBacking',
        title: '退单中数量'
      },
      {
        field: 'volumeBack',
        title: '退单数量'
      },
      {
        field: 'volumeShip',
        title: '已发货数量'
      },
      {
        field: 'volumeShipSending',
        title: '配送中数量'
      },
      {
        field: 'volumeShipReceiptInventory',
        title: '已收货数量'
      },
      {
        field: 'volumeShipReceiptBacking',
        title: '退货中数量'
      },
      {
        field: 'volumeShipReceiptBack',
        title: '退货数量'
      },
      {
        field: 'volumeWaitShip',
        title: '待发货数量'
      },
      {
        field: 'total',
        title: '总金额（元）'
      }]
    },
    columns () {
      let datas = this.orderType === 'prescription' ? this.columnsPrescription : this.columnsBase
      let vm = this
      if (this.action === 'ship') {
        datas.push({
          field: 'newVolumeShip',
          width: 100,
          fixed: 'right',
          title: '发货数量',
          render: (h, rowData) => {
            return h(Volume, {
              props: {
                volume: rowData.newVolumeShip,
                check: (volume) => {
                  return volume > -1 && !(volume > rowData.volumeWaitShip)
                }
              },
              on: {
                change: (volume) => {
                  let data = []
                  vm.dataList.forEach(v => {
                    if (v.id == rowData.id) {
                      rowData.newVolumeShip = volume
                    }
                    data.push(v)
                  })
                  vm.dataList = data
                  vm.$emit('shipChange', vm.dataList)
                }
              }
            })
          }
        })
      }
      if (this.action === 'back_pay') {
        datas.push({
          field: 'applyVolumeShipReceiptBack',
          fixed: 'right',
          width: 100,
          title: '退货数',
          render: (h, rowData) => {
            return h(Volume, {
              props: {
                volume: rowData.applyVolumeShipReceiptBack,
                check: (volume) => {
                  let canBack = (rowData.volumeShipReceiptInventory || 0) - (rowData.volumeShipReceiptBacking || 0) - (rowData.volumeShipReceiptBack || 0)
                  return volume > -1 && !(volume > canBack)
                }
              },
              on: {
                change: (volume) => {
                  let data = []
                  vm.dataList.forEach(v => {
                    if (v.id == rowData.id) {
                      rowData.applyVolumeShipReceiptBack = volume
                    }
                    data.push(v)
                  })
                  vm.dataList = data
                  vm.$emit('backChange', vm.dataList)
                }
              }
            })
          }
        })
      }
      if (this.action === 'back') {
        datas.push({
          field: 'applyVolumeBack',
          width: 100,
          fixed: 'right',
          title: '未发货退货数',
          render: (h, rowData) => {
            return h(Volume, {
              props: {
                volume: rowData.applyVolumeBack,
                check: (volume) => {
                  return volume > -1 && !(volume > rowData.volumeWaitShip)
                }
              },
              on: {
                change: (volume) => {
                  let data = []
                  vm.dataList.forEach(v => {
                    if (v.id == rowData.id) {
                      rowData.applyVolumeBack = volume
                    }
                    data.push(v)
                  })
                  vm.dataList = data
                  vm.$emit('backChange', vm.dataList)
                }
              }
            })
          }
        })
        datas.push({
          field: 'applyVolumeShipReceiptBack',
          fixed: 'right',
          width: 100,
          title: '已发货退货数',
          render: (h, rowData) => {
            return h(Volume, {
              props: {
                volume: rowData.applyVolumeShipReceiptBack,
                check: (volume) => {
                  let canBack = (rowData.volumeShipReceiptInventory || 0) - (rowData.volumeShipReceiptBacking || 0) - (rowData.volumeShipReceiptBack || 0)
                  return volume > -1 && !(volume > canBack)
                }
              },
              on: {
                change: (volume) => {
                  let data = []
                  vm.dataList.forEach(v => {
                    if (v.id == rowData.id) {
                      rowData.applyVolumeShipReceiptBack = volume
                    }
                    data.push(v)
                  })
                  vm.dataList = data
                  vm.$emit('backChange', vm.dataList)
                }
              }
            })
          }
        })
      }
      return datas
    }
  },
  methods: {
    async loadData () {
      if (this.orderId) {
        this.loading = true
        let parm = {
          orderId: this.orderId
        }
        this.dataList = await orderDetailRequest.get(parm)
        this.dataList.forEach((v) => {
          v.backingNum = v.volumeBacking + v.volumeShipReceiptBacking
          v.backNum = v.volumeBack + v.volumeShipReceiptBack
          v.total = Number((v.price * v.volume).toFixed(2))
          v.newVolumeShip = 0
          v.applyVolumeBack = 0
          v.applyVolumeShipReceiptBack = 0
        })
        this.loading = false
        this.$emit('shipChange', this.dataList)
        this.$emit('backChange', this.dataList)
      }
    }
  },
  data () {
    return {
      dataList: [],
      loading: false
    }
  }
}
</script>

<style lang="less" scope>
.order-detail {
  width: 100%;
  height: 100%;
}
.left-page {
  display: block;
  flex: 2;
}
.right-page {
  flex: 1;
}
</style>