<template>
  <div class="ship-block" v-loadingx="loading">
    <base-info :order-id="orderId"></base-info>
    <div class="ship-form">
      <fm-title title-text="快递信息"></fm-title>
      <fm-form :inline="3">
        <fm-form-item label="快递公司">
          <fm-input-new v-model="deliveryOrder.deliveryCompany"/>
        </fm-form-item>
        <fm-form-item label="快递单号">
          <fm-input-new v-model="deliveryOrder.code"/>
        </fm-form-item>
        <fm-form-item label="收货人">
          <fm-input-new v-model="deliveryOrder.receiptName"/>
        </fm-form-item>
        <fm-form-item label="收货人电话">
          <fm-input-new v-model="deliveryOrder.receiptPhone"/>
        </fm-form-item>
        <fm-form-item label="收货地址">
          <fm-input-new v-model="deliveryOrder.receiptAddress"/>
        </fm-form-item>
        <fm-form-item label="备注说明">
          <fm-input-new v-model="deliveryOrder.shipDescription"/>
        </fm-form-item>
      </fm-form>
    </div>
    <buy-info :order-id="orderId"></buy-info>
    <detail :order-id="orderId" ref="shipOrderDetail" @shipChange="shipChange" action="ship"></detail>
    <div class="btm-btn">
      <fm-btn style="margin-right:3rem" @click="save">发货</fm-btn>
      <fm-btn @click="cancel">取消</fm-btn>
    </div>
  </div>
</template>

<script>
import {
  deliveryOrderRequest,
  orderRequest
} from '@/api'

import BaseInfo from './baseInfo'
import BuyInfo from './buyInfo'
import Detail from './detail'

export default {
  name: 'OrderShip',
  components: {
    BaseInfo,
    BuyInfo,
    Detail
  },
  props: {
    orderId: {
      type: Number
    }
  },
  data () {
    return {
      deliveryOrder: {},
      detailList: [],
      order: null,
      loading: true
    }
  },
  created () {
    this.loadOrder()
  },
  methods: {
    async loadOrder () {
      this.loading = true
      let datas = await orderRequest.get({id: this.orderId})
      this.order = datas[0]
      this.deliveryOrder.shipMainbodyId = this.order.saleMainbodyId
      this.deliveryOrder.receiptMainbodyId = this.order.buyMainbodyId
      this.$set(this.deliveryOrder, 'receiptAddress', this.order.receiptAddress)
      this.$set(this.deliveryOrder, 'receiptName', this.order.receiptName)
      this.$set(this.deliveryOrder, 'receiptPhone', this.order.receiptPhone)
      this.loading = false
    },
    shipChange (data) {
      this.detailList = data.filter(v => v.newVolumeShip).map((v) => {
        return {
          sourceData: [{
            sourceType: 'order_detail',
            sourceDataId: v.id,
            volume: v.newVolumeShip
          }]
        }
      })
    },
    cancel () {
      this.$emit('cancel')
    },
    async save () {
      if (this.detailList.length === 0) {
        this.$notice.info({
          title: '系统提示',
          desc: '无发货数量，无法发货!'
        })
        return
      }
      this.loading = true
      let parm = Object.assign({}, this.deliveryOrder, {
        orderIds: String(this.orderId)
      })
      parm.details = this.detailList
      this.detailList = []
      await deliveryOrderRequest.add(parm)
      this.$notice.info({
        title: '系统提示',
        desc: '发货完成!'
      })
      this.loading = false
      this.$refs['shipOrderDetail'].loadData()
      this.$emit('shipOver')
    }
  }
}
</script>


<style lang="less" scope>
.btm-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}
</style>