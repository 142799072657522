import {
  localstorageHelper
} from '@/fmlib'

const state = {
  userInfo: {},
  availableMenus: [],
  availableRoles: [],
  availableFuns: [],
  currentRole: null,
  userPrevilege: [],
  userMainbodyId: null,
  overviewRole: localStorage.getItem('overviewRole') === 'true'
}

const getMenu = (menus) => {
  let findMenu = null
  let i = 0
  while (!findMenu && i < menus.length) {
    let v = menus[i]
    if (v.url) {
      findMenu = v
    } else if (v.children && v.children.length > 0) {
      findMenu = getMenu(v.children)
    }
    i += 1
  }
  return findMenu
}

const getters = {
  userInfo: state => state.userInfo,
  userPrevilege: state => state.userPrevilege,
  availableMenus: state => state.availableMenus,
  availableFuns: state => state.availableFuns,
  availableFunKeys: state => {
    return state.availableFuns.map(v => v.funKey)
  },
  userMainbodyId: state => state.userMainbodyId,
  availableRoles: state => state.availableRoles,
  currentRole: state => state.currentRole,
  isWorker: (state) => {
    return state.availableRoles.find(v => v.name === '员工')
  },
  userFirstPage: (state) => {
    let goMenu = getMenu(state.availableMenus)
    return {name: goMenu && goMenu.url ? goMenu.url : 'home'}
  },
  defaultRole: state => state.availableRoles.length > 0 ? state.availableRoles[0] : null,
  overviewRole: state => state.overviewRole
}

const actions = {
  setUserData ({ dispatch }, data) {
    dispatch('setUserInfo', {
      name: data.name,
      id: data.id
    })
    dispatch('setAvailableRoles', data.roles || [])
    let roleId = localstorageHelper.getData('roleId')
    if (data.roles && data.roles.length > 0) {
      if (roleId && data.roles.map(v => v.id).includes(roleId)) {
        dispatch('setCurrentRole', roleId)
      } else {
        dispatch('setCurrentRole', data.roles[0].id)
      }
    }
  },
  setUserInfo ({ commit }, data) {
    commit('setUserInfo', data)
  },
  setUserPrevilege ({ commit }, data) {
    commit('setUserPrevilege', data)
  },
  setAvailableMenus ({ commit }, data) {
    commit('setAvailableMenus', data)
  },
  setAvailableFuns ({ commit }, data) {
    commit('setAvailableFuns', data)
  },
  setAvailableRoles ({ commit }, data) {
    commit('setAvailableRoles', data)
  },
  setCurrentRole ({ commit, getters, dispatch }, roleId) {
    let role = getters.availableRoles.find(v => v.id === roleId)
    if (role && role.id) {
      localstorageHelper.updateData('roleId', roleId)
    }
    commit('setCurrentRole', role)
    dispatch('setAvailableMenus', role && role.menus ? role.menus.filter(v => v.clientType === getters.clientType) : [])
    dispatch('setAvailableFuns', role && role.funs ? role.funs : [])
    dispatch('setUserPrevilege', role && role.authorities ? role.authorities : [])
    commit('setUserMainbodyId', role && role.authorities && role.authorities.length > 0 ? role.authorities[0].objId : null)
  },
  setOverviewRole ({ commit }, val) {
    commit('setOverviewRole', val)
  }
}

const mutations = {
  setUserInfo (state, data) {
    state.userInfo = data
  },
  setUserPrevilege (state, data) {
    state.userPrevilege = data
  },
  setAvailableMenus (state, data) {
    state.availableMenus = data
  },
  setAvailableFuns (state, data) {
    state.availableFuns = data
  },
  setAvailableRoles (state, data) {
    state.availableRoles = data
  },
  setCurrentRole (state, data) {
    state.currentRole = data
  },
  setUserMainbodyId (state, data) {
    state.userMainbodyId = data
  },
  setOverviewRole (state, data) {
    state.overviewRole = data
    localStorage.setItem('overviewRole', data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
