<template>
  <div class="order-info">
    <base-info @getOrder="getOrder" :order-id="orderId"></base-info>
    <buy-info :order-id="orderId"></buy-info>
    <detail :order-type="order ? order.type : null" ref="orderInfoOrderDetail" :order-id="orderId"></detail>
    <delivery-order v-if="order && order.type !== 'prescription'" @orderDetailUpdate="orderDetailUpdate" :order-id="orderId"></delivery-order>
    <!-- <order v-if="order && order.type === 'normal'" :hide-data-null="true" title="退货信息" class="all-info-order" order-type="back,back_order" :load-parm="{sourcetype:'order', sourceDataId: orderId}"></order> -->
    <fm-title title-text="退货信息" v-if="backDataList.length > 0 && order && order.type === 'normal'"></fm-title>
    <order-table v-if="backDataList.length > 0 && order && order.type === 'normal'" :auto-height="false" :data-list="backDataList" @dataUpdate="loadBackData" show-type="normal"></order-table>
  </div>
</template>

<script>
import BaseInfo from './baseInfo'
import BuyInfo from './buyInfo'
import Detail from './detail'
import DeliveryOrder from './deliveryOrder'

import {
  orderRequest
} from '@/api'

export default {
  name: 'AllInfo',
  components: {
    BaseInfo,
    BuyInfo,
    Detail,
    DeliveryOrder
  },
  props: {
    orderId: {
      type: Number
    }
  },
  data () {
    return {
      order: null,
      loading: false,
      backDataList: []
    }
  },
  created () {
    this.loadBackData()
  },
  methods: {
    orderDetailUpdate () {
      this.$refs['orderInfoOrderDetail'].loadData()
    },
    async loadBackData () {
      this.loading = true
      let parm = {
        typeList: 'back,back_order',
        statusList:'wait,pass,down',
        sourcetype:'order', 
        sourceDataId: this.orderId
      }
      this.backDataList = await orderRequest.get(parm)
      let statusMap = {
        edit: '调整中',
        wait: '待审核',
        pass: '审核通过',
        down: '审核不通过'
      }
      this.backDataList.forEach(v => {
        v.statusText = statusMap[v.status]
        v.makeType = v.type.includes('back') ? '退货' : (v.makeMainbodyId === v.buyMainbodyId ? '自主下单' : '代客下单')
      })
      this.loading = false
    },
    getOrder (order) {
      this.order = order
    }
  }
}
</script>

<style lang="less" scope>
.all-info-order {
  height: 20rem !important;
  .sign-page-div {
    margin: 0 !important;
  }
}
</style>