import {
  menuRequest,
  medicineRequest,
  funRequest,
  roleRequest,
  userRequest,
  mainbodyRequest,
  medicineTypeRequest
} from '@/api'

import {
  tools
} from '@/fmlib'

const dealData = (data, key, label) => {
  let newData = []
  data.forEach((item) => {
    let itemLabel = null

    if (Array.isArray(label)) {
      itemLabel = label.map(v => item[v]).join(' ')
    } else {
      itemLabel = item[label]
    }

    newData.push({
      key: item[key],
      label: itemLabel,
      data: item
    })
  })
  return newData
}

const state = {
  // 正在加载的数据
  baseDataLoading: {},
  // 已加载数据
  baseDataLoaded: {},
  // 菜单
  menuList: [],
  // 功能
  funList: [],
  // 角色
  roleList: [],
  // 菜单类型
  menuTypeList: [{key: 'menu', label: '菜单'}, {key: 'sys_page', label: '系统页面'}, {key: 'out_page', label: '外部链接'}],
  // 客户端类型
  clientTypeList: [{key: 'web', label: '电脑'}, {key: 'wap', label: '手机'}],
  // 当前客户端类型
  clientType: navigator.userAgent.match(/Android|iPhone|iPad|iPod/) === null ? 'web' : 'wap',
  // user
  userList: [],
  // mainbodyList
  mainbodyList: [],
  // medicineList
  medicineList: [],
  // medicineTypeList
  medicineTypeList: []
}

const loadData = async function ({commit, getters, reLoad, dataKey, request, mutation, dealSourceData, parm}) {
  if ((reLoad || !getters.baseDataLoaded[dataKey]) && !getters.baseDataLoading[dataKey]) {
    commit('updateBaseDataLoading', {key: dataKey, value: true})
    let dataList = await request(parm)
    commit(mutation, dealSourceData(dataList))
    commit('updateBaseDataLoaded', dataKey)
    commit('updateBaseDataLoading', {key: dataKey, value: false})
    return getters[dataKey]
  } else if (getters.baseDataLoading[dataKey]) {
    while (getters.baseDataLoading[dataKey]) {
      await tools.sleep(200)
    }
    return getters[dataKey]
  } else {
    return Promise.resolve(getters[dataKey])
  }
}

const getters = {
  baseDataLoading: state => state.baseDataLoading,
  baseDataLoaded: state => state.baseDataLoaded,
  menuList: state => state.menuList,
  funList: state => state.funList,
  roleList: state => state.roleList,
  menuTypeList: state => state.menuTypeList,
  clientTypeList: state => state.clientTypeList,
  clientType: state => state.clientType,
  userList: state => state.userList,
  mainbodyList: state => state.mainbodyList,
  medicineList: state => state.medicineList,
  medicineTypeList: state => state.medicineTypeList
}

const actions = {
  async loadUserList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'userList', request: userRequest.get, mutation: 'setUserList', dealSourceData})
  },
  setUserList ({ commit }, data) {
    commit('setUserList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'userList')
  },
  async loadRoleList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList.forEach(v => v.name = v.name === 'admin' ? '超级管理员' : v.name)
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'roleList', request: roleRequest.get, mutation: 'setRoleList', dealSourceData})
  },
  setRoleList ({ commit }, data) {
    commit('setRoleList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'roleList')
  },
  async loadMenuList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      dataList = tools.treeToList(dataList, 'children')
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'menuList', request: menuRequest.get, mutation: 'setMenuList', dealSourceData})
  },
  setMenuList ({ commit }, data) {
    commit('setMenuList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'menuList')
  },
  async loadFunList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['funKey', 'name'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'funList', request: funRequest.get, mutation: 'setFunList', dealSourceData})
  },
  setFunList ({ commit }, data) {
    commit('setFunList', dealData(data, 'id', ['funKey', 'name']))
    commit('updateBaseDataLoaded', 'funList')
  },
  async loadMainbodyList ({ commit, getters }, reLoad) {
    let typeLabelMap = {
      supplier: '供应商',
      clinic: '诊所',
      patient: '患者'
    }
    let dealSourceData = (dataList) => {
      dataList.forEach(v => {
        v.typeLabel = typeLabelMap[v.dataType] || ''
      })
      return dealData(dataList, 'id', ['typeLabel', 'label'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'mainbodyList', request: mainbodyRequest.getAll, mutation: 'setMainbodyList', dealSourceData})
  },
  setMainbodyList ({ commit }, data) {
    commit('setMainbodyList', dealData(data, 'id', ['typeLabel', 'label']))
    commit('updateBaseDataLoaded', 'mainbodyList')
  },
  async loadMedicineList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', ['name', 'packing', 'manufacturer'])
    }
    return await loadData({commit, getters, reLoad, dataKey: 'medicineList', request: medicineRequest.get, mutation: 'setMedicineList', dealSourceData})
  },
  setMedicineList ({ commit }, data) {
    commit('setMedicineList', dealData(data, 'id', ['name', 'packing', 'manufacturer']))
    commit('updateBaseDataLoaded', 'medicineList')
  },
  async loadMedicineTypeList ({ commit, getters }, reLoad) {
    let dealSourceData = (dataList) => {
      return dealData(dataList, 'id', 'name')
    }
    return await loadData({commit, getters, reLoad, dataKey: 'medicineTypeList', request: medicineTypeRequest.get, mutation: 'setMedicineTypeList', dealSourceData})
  },
  setMedicineTypeList ({ commit }, data) {
    commit('setMedicineTypeList', dealData(data, 'id', 'name'))
    commit('updateBaseDataLoaded', 'medicineTypeList')
  }
}

const mutations = {
  updateBaseDataLoading (state, {key, value}) {
    state.baseDataLoading[key] = value
  },
  updateBaseDataLoaded (state, key) {
    state.baseDataLoaded[key] = true
  },
  setMenuList (state, data) {
    state.menuList = data
  },
  setFunList (state, data) {
    state.funList = data
  },
  setRoleList (state, data) {
    state.roleList = data
  },
  setUserList (state, data) {
    state.userList = data
  },
  setMainbodyList (state, data) {
    state.mainbodyList = data
  },
  setMedicineList (state, data) {
    state.medicineList = data
  },
  setMedicineTypeList (state, data) {
    state.medicineTypeList = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
