<template>
  <div>
    <info-block
    value-color="red"
    :data="order"
    :fields="fields"
    :title="order && order.id ? (order.type === 'prescription' ? '订单信息' : '采购信息') : ''">
    </info-block>
  </div>
</template>

<script>
import InfoBlock from '@/components/base/InfoBlock'

import {
  orderRequest
} from '@/api'

export default {
  name: 'BuyInfo',
  components: {
    InfoBlock
  },
  props: {
    orderId: {
      type: Number
    }
  },
  computed: {
    fields: {
      get () {
        let data = [{
          title: '总金额',
          field: 'totalAmount',
          format: (data) => {
            return data.bill && data.bill.totalAmount ? data.bill.totalAmount : '-'
          }
        },
        {
          title: '运费',
          field: 'yf',
          format: () => {
            return '-'
          }
        },
        {
          title: '优惠券金额',
          field: 'discount',
          format: (data) => {
            return data.bill && data.bill.discount ? data.bill.discount : '-'
          }
        },
        {
          title: '减免金额',
          field: 'otherFee',
          format: (data) => {
            return data.bill && data.bill.otherFee ? data.bill.otherFee : '-'
          }
        },
        {
          title: '应付金额',
          field: 'payable',
          format: (data) => {
            return data.bill && data.bill.payable ? data.bill.payable : '-'
          }
        },
        {
          title: '实付金额',
          field: 'paid',
          format: (data) => {
            return data.bill && data.bill.paid ? data.bill.paid : '-'
          }
        },
        {
          title: '已退金额',
          field: 'yt',
          format: (data) => {
            return data.bill && data.bill.refund ? data.bill.refund : '-'
          }
        }]
        if (!this.order || !this.order.id) {
          return []
        }
        if (this.order.type === 'prescription') {
          data = data.filter(v => v.field !== 'discount')
        }
        return data
      }
    }
  },
  created () {
    this.loadData()
  },
  data () {
    return {
      order: {}
    }
  },
  methods: {
    async loadData () {
      let datas = await orderRequest.get({id: this.orderId})
      this.order = datas[0]
    }
  }
}
</script>
