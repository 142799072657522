<template>
  <div style="width: 100px;">
    <fm-input-number @change="change" v-model="currentVolume" size="small"></fm-input-number>
  </div>
</template>

<script>
export default {
  props: {
    volume: {
      type: Number
    },
    check: {
      type: Function
    }
  },
  watch: {
    volume: {
      handler () {
        console.log('handler', this.volume, this.currentVolume)
        this.currentVolume = this.volume
      },
      immediate: true,
      deep: true
    },
    currentVolume (value) {
      if (value !== this.volume) {
        this.change(value)
      }
    }
  },
  data () {
    return {
      currentVolume: null
    }
  },
  methods: {
    change (value) {
      if (this.check && this.check(value)) {
        this.$emit('change', value)
      } else {
        this.$nextTick(() => {
          this.currentVolume = this.volume
        })
      }
    }
  }
}
</script>
